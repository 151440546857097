import React, { useCallback } from 'react';
import { requestBed } from '../../actions/beds';
import { AddressAutofill } from '@mapbox/search-js-react';
import {useSelector} from "react-redux";

const RequestBedForm = (props) => {
    const user = useSelector(state => state.user.user);
    const validateForm = useCallback((event) => {
        event.preventDefault();
        const form = event.target;
        requestBed(form, user).then((result) => {
            if (result) {
                form.client.value = '';
                form.date.value = '';
                form.address.value = '';
                form.city.value = '';
                form.postcode.value = '';
                form.comment.value = '';
            }
        });
    }, []);
    return (
        <form onSubmit={validateForm} className="formRequest">
            <div className="form-group">
                <label for="clientInput">Client</label>
                <input type="text" name="client" className="form-control" id="clientInput" placeholder="Client" />
            </div>
            <div className="form-group">
                <label for="dateInput">Date</label>
                <input name="date" type="datetime-local" className="form-control" id="dateInput" />
            </div>

            <div className="form-group">
                Adresse
                <AddressAutofill accessToken="pk.eyJ1IjoibG91aXNjcmVtZXJzIiwiYSI6ImNsZjlzdXJzNTAzeTAzb3FkcGs4ZDRrN2UifQ.T1bvG4WwOLUKMGj83SxZHw">
                    <input
                        name="address" placeholder="Adresse" type="text"
                        autoComplete="address-line1" className="form-control"
                    />
                </AddressAutofill>
                <input
                    name="postcode" placeholder="Code Postale" type="text"
                    autoComplete="postal-code" className="form-control"
                />
                <input
                    name="city" placeholder="Ville" type="text"
                    autoComplete="address-level2" className="form-control"
                />
            </div>
            <div className="form-group">
                <label htmlFor="pharmacy">Pharmacie</label>
                <select name="pharmacy" id="pharmacy" className="form-control">
                    <option value="Cremers">Cremers</option>
                    <option value="Violaine">Violaine</option>
                    <option value="Mont à camp">Mont à Camp</option>
                    <option value="Grand Stade">Grand Stade</option>
                </select>
            </div>
            <div className="form-group">
                <label htmlFor="comment">Commentaires</label>
                <textarea name="comment" id="comment" className="form-control"/>
            </div>
            <div className="form-group">
                <input type="submit" value="Envoyer" className="btn btn-primary" />
            </div>
        </form>
    );
};

export {
    RequestBedForm
};