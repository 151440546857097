const style = {
    Core: {
        width: '100%',
        padding: '20px',
    },
    CompoHome: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        minHeight: 'calc(100vh - 150px)',
        backgroundColor: '#f0f8ff', // Arrière-plan léger
    },
    img: {
        height: 220,
        '@media (max-width: 768px)': {
            height: 150,
        }
    },
    CompoTitle: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        alignItems: 'center',
    },
    Title: {
        marginTop: 20,
        fontSize: 85,
        color: '#333',
        fontFamily: 'Audiowide',
        '@media (max-width: 768px)': {
            fontSize: 60,
        },
        '@media (max-width: 480px)': {
            fontSize: 40,
        },
    },
    SubTitle: {
        paddingBottom: 25,
        fontSize: 37,
        color: '#008080', // Couleur plus vive pour les sous-titres
        width: 'calc(100% - 80px)',
        maxWidth: 1000,
        fontFamily: 'Questrial',
        borderBottom: '3px solid #008080',
        '@media (max-width: 768px)': {
            fontSize: 28,
        },
        '@media (max-width: 480px)': {
            fontSize: 20,
        },
    },
    TopText: {
        marginTop: 25,
        color: '#333',
        fontSize: 28,
        fontFamily: 'Questrial',
        marginBottom: '1%',
        '@media (max-width: 768px)': {
            fontSize: 22,
        },
        '@media (max-width: 480px)': {
            fontSize: 18,
        },
    },
    Text: {
        color: '#555',
        fontSize: 22,
        fontFamily: 'Questrial',
        textAlign: 'center',
        marginLeft: '20%',
        marginRight: '20%',
        paddingBottom: '2%',
        '@media (max-width: 768px)': {
            fontSize: 18,
            marginLeft: '10%',
            marginRight: '10%',
        },
        '@media (max-width: 480px)': {
            fontSize: 16,
            marginLeft: '5%',
            marginRight: '5%',
        },
    },
    ServicesSection: {
        padding: '50px 0',
        backgroundColor: '#f9f9f9',
        textAlign: 'center',
    },
    SectionTitle: {
        fontSize: 36,
        marginBottom: 20,
        fontFamily: 'Audiowide',
        color: '#333',
        '@media (max-width: 768px)': {
            fontSize: 28,
        },
        '@media (max-width: 480px)': {
            fontSize: 24,
        },
    },
    Services: {
        display: 'flex',
        justifyContent: 'space-around',
        flexWrap: 'wrap',
        '@media (max-width: 768px)': {
            flexDirection: 'column',
            alignItems: 'center',
        },
    },
    ServiceItem: {
        width: '30%',
        padding: 20,
        textAlign: 'center',
        backgroundColor: '#fff',
        borderRadius: 10,
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        transition: 'transform 0.3s',
        '&:hover': {
            transform: 'scale(1.05)',
        },
        '@media (max-width: 768px)': {
            width: '80%',
            marginBottom: 20,
        },
    },
    ServiceImage: {
        width: '100%',
        height: 'auto',
        borderRadius: '5px',
    },
    TestimonialsSection: {
        padding: '50px 0',
        backgroundColor: '#fff',
        textAlign: 'center',
    },
    Testimonials: {
        display: 'flex',
        justifyContent: 'space-around',
        flexWrap: 'wrap',
        '@media (max-width: 768px)': {
            flexDirection: 'column',
            alignItems: 'center',
        },
    },
    TestimonialItem: {
        width: '30%',
        padding: 20,
        fontStyle: 'italic',
        backgroundColor: '#f0f8ff',
        borderRadius: 10,
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        '@media (max-width: 768px)': {
            width: '80%',
            marginBottom: 20,
        },
    },
    TestimonialImage: {
        width: 60,
        height: 60,
        borderRadius: '50%',
        marginBottom: 10,
    },
    ContactSection: {
        padding: '50px 0',
        backgroundColor: '#f9f9f9',
        textAlign: 'center',
    },
    ContactButton: {
        padding: '10px 20px',
        backgroundColor: '#008080',
        color: '#fff',
        border: 'none',
        borderRadius: 5,
        fontSize: 18,
        cursor: 'pointer',
        transition: 'background-color 0.3s ease',
        '&:hover': {
            backgroundColor: '#0056b3',
        },
        '@media (max-width: 768px)': {
            fontSize: 16,
        },
        '@media (max-width: 480px)': {
            fontSize: 14,
        },
    },
};

export default style;
