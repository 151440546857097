import React, { useCallback } from 'react';
import { createBed } from '../../actions/beds';

const CreateBedForm = () => {
    const validateForm = useCallback((event) => {
        event.preventDefault();
        const form = event.target;
        createBed(form).then((result) => {
            if (result) {
                setTimeout(function () {
                    window.location.reload(false);
                }.bind(this), 1000)
            }
        });
    }, []);
    return (
        <div>
            <h1>Créer un lit</h1>
            <form onSubmit={validateForm}>
                <div className="form-group">
                    <label htmlFor="number"> Numéro </label>
                    <input id="number" type="number" className="form-control" />
                    <label htmlFor="client"> Client </label>
                    <input id="client" type="client" className="form-control" />
                    <label htmlFor="pharmacy">Pharmacie</label>
                    <select name="pharmacy" id="pharmacy" className="form-control">
                        <option value="Cremers">Cremers</option>
                        <option value="Violaine">Violaine</option>
                        <option value="Mont à Camp">Mont à Camp</option>
                        <option value="Grand Stade">Grand Stade</option>
                    </select>
                    <label htmlFor="model"> Modèle: </label>
                    <select name="model" id="model" className="form-control">
                        <option value="Euro 3000">Euro 3000</option>
                        <option value="Euro 1600">Euro 1600</option>
                        <option value="Euro 1000">Euro 1000</option>
                        <option value="Euro 1000 Boiserie">Euro 1000 Boiserie</option>
                        <option value="Sotec">Sotec</option>
                        <option value="Autre">Autre</option>
                    </select>
                    <label htmlFor="motors"> Nombre de moteurs: </label>
                    <input id="motors" type="number" className="form-control" pattern="[0-9]*" inputmode="numeric"/>
                    <label htmlFor="year"> Année: </label>
                    <input id="year" type="number" className="form-control" pattern="[0-9]*" inputmode="numeric"/>
                    <label htmlFor="isRent"> En Location ? </label>
                    <input id="isRent" type="checkbox" className="form-check-input" />
                </div>
                <div className="form-group">
                    <input type="submit" value="Créer" className="btn btn-primary" />
                </div>
            </form>
        </div>
    );
};

export {
    CreateBedForm
};