import React from 'react';
import Logo from "../../images/logo.png";
import '../../fonts/Audiowide-Regular.ttf';
import '../../fonts/Questrial-Regular.ttf';
import Header from "../header/header";
import Footer from "../footer/footer";
import { createUseStyles } from "react-jss";
import style from "./home_styles";
const useStyles = createUseStyles(style);

const Home = () => {
    const classes = useStyles();
    return (
        <div>
            <Header />
            <div className={classes.Core}>
                {/* Section principale avec logo et texte d'introduction */}
                <section className={classes.CompoHome}>
                    <img className={classes.img} src={Logo} alt="Logo Pharmagroup" />
                    <div className={classes.CompoTitle}>
                        <h1 className={classes.Title}>Pharmagroup</h1>
                        <h2 className={classes.SubTitle}>Lits médicaux</h2>
                        <p className={classes.TopText}>Livraison rapide et fiable de lits médicaux à domicile partout en France.</p>
                        <p className={classes.Text}>
                            Nous nous engageons à offrir des équipements médicaux de qualité pour améliorer le confort et la sécurité des patients à domicile. Notre équipe est dédiée à vous fournir une assistance rapide et un service client exceptionnel.
                        </p>
                    </div>
                </section>

                {/* Section des services */}
                <section className={classes.ServicesSection}>
                    <h2 className={classes.SectionTitle}>Nos Services</h2>
                    <div className={classes.Services}>
                        <div className={classes.ServiceItem}>
                            {/* <img src={ServiceImage1} alt="Livraison express" className={classes.ServiceImage} /> */}
                            <h3>Livraison express</h3>
                            <p>Nous livrons les lits médicaux directement chez vous dans les plus brefs délais.</p>
                        </div>
                        <div className={classes.ServiceItem}>
                            {/* <img src={ServiceImage2} alt="Installation professionnelle" className={classes.ServiceImage} /> */}
                            <h3>Installation professionnelle</h3>
                            <p>Nos techniciens installent les lits médicaux pour garantir leur bon fonctionnement.</p>
                        </div>
                        <div className={classes.ServiceItem}>
                            {/* <img src={ServiceImage1} alt="Support client 24/7" className={classes.ServiceImage} /> */}
                            <h3>Support client 24/7</h3>
                            <p>Une assistance dédiée est disponible à tout moment pour répondre à vos questions.</p>
                        </div>
                    </div>
                </section>

                {/* Section témoignages */}
                <section className={classes.TestimonialsSection}>
                    <h2 className={classes.SectionTitle}>Témoignages</h2>
                    <div className={classes.Testimonials}>
                        <div className={classes.TestimonialItem}>
                            {/* <img src={TestimonialImage} alt="Marie de Lambersart" className={classes.TestimonialImage} /> */}
                            <p>"Un service rapide et efficace. Le lit a été livré et installé le jour même, parfait pour mon père."</p>
                            <span>- Marie, Lambersart</span>
                        </div>
                        <div className={classes.TestimonialItem}>
                            {/* <img src={TestimonialImage} alt="Jean de Lomme" className={classes.TestimonialImage} /> */}
                            <p>"Excellente communication et service client. Le lit est de haute qualité, je recommande !"</p>
                            <span>- Jean, Lomme</span>
                        </div>
                    </div>
                </section>

                {/* Section contact */}
                <section className={classes.ContactSection}>
                    <h2 className={classes.SectionTitle}>Contactez-nous</h2>
                    <p>Pour toute question ou réservation, contactez-nous directement par téléphone ou via notre formulaire en ligne.</p>
                    <button className={classes.ContactButton}>Nous Contacter</button>
                </section>
            </div>
            <Footer />
        </div>
    );
}

export default Home;
